<template>
  <v-card flat>
    <v-data-table
      :items="table"
      :headers="[
        { text: 'Mannschaft', value: 'team.name', sortable: false },
        ...geraete(e, df).map(d => ({ text: d.name, value: d._discipline, sortable: false, align: 'center' })),
        { text: 'Punkte', value: 'final', sortable: false, align: 'center' }
      ]"
      hide-default-footer
      :mobile-breakpoint="0"
      :items-per-page="-1"
      class="table"
    >
      <template #item="{item}">
        <tr :class="{ even: item.i % 2 === 0, odd: item.i % 2 === 1 }">
          <td style="font-size: 80%; font-weight: bold;">{{ item.team.name }}</td>
          <td
            v-for="d in geraete(e, df)"
            :key="d._discipline"
            style="font-size: 60%; text-align: center;"
          >
            <span v-if="item.geraete[d._discipline].aktiv" style="font-size:60%;font-style:italic;color:gray;">
              am Gerät
            </span>
            <template v-else>
              {{ item.geraete[d._discipline].result | float2 }}
            </template>
          </td>
          <td style="font-size: 80%; text-align: center; font-weight: bold;">
            {{ item.final | float2_0 }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { useCalc } from '@/views/components/stb_m_2021/plugins/calc'

export default {
  name: 'lf',

  setup (props, context) {
    return {
      ...useCalc(props, context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    r: {
      type: Array,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  computed: {
    table () {
      return this.e.teams.map(t => ({
        ...t,
        geraete: this.geraete(this.e, this.df).map(d => ({
          ...d,
          result: this.ergebnis(t.team._id, d._discipline),
          aktiv: this.isAktiv(t.team._id, d._discipline)
        })).reduce((acc, curr) => {
          acc[curr._discipline] = curr
          return acc
        }, {})
      })).map(t => ({
        ...t,
        final: Object.values(t.geraete).reduce((acc, curr) => acc + curr.result, 0)
      })).sort((a, b) => a.final > b.final ? -1 : 1).map((t, i) => ({
        ...t,
        i
      }))
    }
  },

  methods: {
    ergebnis (tid, did) {
      const results = this.mannschaftturnerangeraet(this.e, this.r, tid, did)

      if (results.every(r => r.result.final !== null)) {
        return this.mannschaftgeraetergebnis(this.e, this.r, tid, did)
      }
      return 0
    },
    isAktiv (tid, did) {
      const results = this.mannschaftturnerangeraet(this.e, this.r, tid, did)
      return results.some(r => r.result.final !== null) && !results.every(r => r.result.final !== null)
    }
  }
}
</script>

<style scoped>
.table {
  font-size: 150%;
}
</style>
